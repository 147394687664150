.theme-search {
  @apply relative rounded-md shadow-sm;
  border-bottom: 1px solid var(--common-border-light-color);
}

.theme-search input[type="text"]{
  @apply border-none  w-full block py-4 pl-14 sm:text-sm;
  background-color: var(--common-bg-color);
  border-bottom-color: var(--common-border-color);
}

.theme-item {
  @apply px-5 py-2 flex items-center justify-between gap-5 border-b cursor-pointer;
  @apply hover:bg-gray-100;
  border-bottom-color: var(--common-border-light-color);
  color: var(--common-semidark-text-color);
}

.theme-item:hover{
  background-color: var(--common-light-bg-color);
}

.theme-item.is-preview{
  background-color: var(--common-light-bg-color);
  color: var(--common-text-color);
}

.theme-status{
  color: var(--common-dark-text-color);
}

.theme-status-active{
  color: var(--common-primary-color);
}

/* Theme Listing */

.theme-listing-search {
  background-color: transparent !important;
  border-bottom: 1px solid var(--common-border-light-color) !important;
}


