.tiptap-box.is-dragging-over {
  position: relative;
}

.tiptap-box.is-dragging-over:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border: 2px dotted;
  z-index: 1;
  background: rgba(0,0,0,.2);
}

.pad-tag {
  font-size: 0.625rem;
  @apply flex uppercase;
}

.pad-list > :not([hidden]):not(:last-child) {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--common-border-color);
}

.pad-list .pad-item {
  @apply relative;
  @apply relative dark:bg-gray-800 cursor-pointer bg-white py-3 px-4;
  background-color: var(--sidebar2-background-color)
}

.pad-item-title {
  @apply text-base font-medium text-gray-900;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--common-text-color);
  max-width: calc(100% - 15px);
}

.pad-unimportant-icon { 
  color: var(--common-dark-text-color);
}

.pad-important-icon { 
  color: var(--common-text-color);
}

.pad-list .pad-item.active {
  @apply dark:bg-gray-800;
}

.pad-list .pad-item:hover:before,
.pad-list .pad-item.active:before{
  @apply absolute top-0 left-0;
  content: "";
  width: 3px;
  height: 100%;
}

.pad-list .pad-item.active:before {
  /* @apply bg-gray-600 dark:bg-gray-400; */
  background-color: var(--sidebar2-item-active-color);
}

.pad-list .pad-item:hover:before {
  /* @apply bg-gray-400 dark:bg-gray-600; */
  background-color: var(--sidebar2-item-hover-color);
}

.pad-list .pad-item.context-menu-opened {
  background-color: var(--common-dark-bg-color);
}

.pad-details .tag-item, 
.pad-details .tag-select-btn{
  @apply bg-black bg-opacity-40;
} 

.pad-details .tag-title,
.pad-details .tag-select-btn{
  @apply text-gray-300;
}

.pad-details .tag-del {
  @apply bg-black text-red-400 hover:text-red-300 bg-opacity-40; 
}

.pad-details .folder-del {
  @apply bg-black text-red-400 hover:text-red-300;
}

.pad-cover-upload {
  @apply transition opacity-0;
}

.pad-info-wrapper:hover .pad-cover-upload {
  @apply opacity-100;
}

.tag-item{
  @apply flex gap-1 items-center py-0.5 px-2 rounded-md relative;
  @apply bg-opacity-90;
  background-color: var(--tag-bg-color);
  color: var(--tag-text-color);
}

/* Zoom level from 1 */
.zoom-level-2 .tiptap-main-content {
  zoom: 1.1;
  width: 733px;
}

.zoom-level-3 .tiptap-main-content {
  zoom: 1.2;
  width: 677px;
}

.zoom-level-4 .tiptap-main-content {
  zoom: 1.3;
  width: 640px;
}

.zoom-level-5 .tiptap-main-content {
  zoom: 1.4;
  width: 599px;
}
