.modal {
  @apply shadow-lg rounded-lg relative text-left overflow-hidden shadow-xl transform transition-all ;
  background-color: var(--modal-bg-color);
  border: 1px solid var(--modal-border-color);
  color: var(--common-text-color);
}

.modal-footer {
  @apply flex items-center justify-between w-full border-t px-5 py-3;
  background-color: var(--modal-footer-bg-color);
  border-top-color: var(--common-border-light-color);
}

.is-web-app .main-modal {
  @apply z-50;
}

