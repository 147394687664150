.ProseMirror:focus {
  outline: none;
}

.ProseMirror {
  caret-color: rgb(33, 164, 220);
  caret-shape: bar;
  font-size: 1em;
  line-height: 1.5;
  color: var(--editor-text-color);
}

.ProseMirror h4 {
  @apply text-lg;
}
.ProseMirror h3 {
  @apply text-xl;
}
.ProseMirror h2 {
  @apply text-2xl;
}
.ProseMirror h1 {
  @apply text-3xl;
}

.ProseMirror a {
  @apply hover:underline cursor-pointer;
  color: var(--editor-link-text-color);
}

/* Spacing */
.ProseMirror p,
.ProseMirror h4,
.ProseMirror h3,
.ProseMirror h2,
.ProseMirror h1,
.ProseMirror blockquote,
.ProseMirror img {
  @apply mb-8;
}

.ProseMirror h4,
.ProseMirror h3,
.ProseMirror h2,
.ProseMirror h1 {
  @apply font-bold;
}

.ProseMirror ul p,
.ProseMirror ol p {
  @apply mb-4;
}

.ProseMirror hr {
  @apply my-8;
  border-color: var(--common-dark-text-color);
}

.ProseMirror code {
  @apply px-1 rounded-sm;
  background-color: var(--tag-bg-color);
  color: var(--tag-text-color);
}

.ProseMirror ul,
.ProseMirror ol {
  @apply pl-12 mb-8;
}

.ProseMirror ul {
  @apply list-disc;
}

.ProseMirror ol {
  @apply list-decimal;
}

.ProseMirror blockquote {
  @apply border-l-4 pl-8 italic font-serif text-lg;
  color: var(--editor-quote-text-color);
  border-color: var(--common-border-hl-color);
}

.ProseMirror blockquote p {
  @apply mb-4;
}

.ProseMirror ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
  @apply flex flex-col gap-2;
}

.ProseMirror ul[data-type="taskList"] p {
  margin: 0;
}

.ProseMirror ul[data-type="taskList"] li {
  display: flex;
  @apply px-3 py-2 shadow-sm rounded-md border;
  border-color: var(--common-border-light-color);
  background-color: var(--common-darker-bg-color);
}

.ProseMirror ul[data-type="taskList"] li > label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
}

.ProseMirror ul[data-type="taskList"] li > label input {
  @apply -mt-1 rounded border border-gray-400 dark:bg-gray-400 dark:border-gray-700 cursor-pointer hover:bg-yellow-50 focus:ring-transparent focus:ring-offset-0;
}

.ProseMirror ul[data-type="taskList"] li > label input:checked {
  @apply bg-emerald-500 border-emerald-500;
}

.ProseMirror ul[data-type="taskList"] li > div {
  flex: 1 1 auto;
}

.ProseMirror img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
}
.ProseMirror img.ProseMirror-selectednode {
  outline: 3px solid #68cef8;
}

/* DARK MODE */

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5 {
  @apply dark:text-gray-100;
}

.ProseMirror p,
.ProseMirror code {
  @apply dark:text-gray-300;
}

.ProseMirror hr {
  @apply dark:border-gray-700;
}

.ProseMirror mark {
  @apply dark:text-gray-900;
}

/* Code block theme */
.ProseMirror pre {
  background: #0d0d0d;
  border-radius: 0.5rem;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  margin-bottom: 2rem;
}
.ProseMirror pre code {
  background: none !important;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}

.ProseMirror pre .hljs-comment,
.ProseMirror pre .hljs-quote {
  color: #616161;
}
.ProseMirror pre .hljs-variable,
.ProseMirror pre .hljs-template-variable,
.ProseMirror pre .hljs-attribute,
.ProseMirror pre .hljs-tag,
.ProseMirror pre .hljs-name,
.ProseMirror pre .hljs-regexp,
.ProseMirror pre .hljs-link,
.ProseMirror pre .hljs-selector-id,
.ProseMirror pre .hljs-selector-class {
  color: #f98181;
}
.ProseMirror pre .hljs-number,
.ProseMirror pre .hljs-meta,
.ProseMirror pre .hljs-built_in,
.ProseMirror pre .hljs-builtin-name,
.ProseMirror pre .hljs-literal,
.ProseMirror pre .hljs-type,
.ProseMirror pre .hljs-params {
  color: #fbbc88;
}
.ProseMirror pre .hljs-string,
.ProseMirror pre .hljs-symbol,
.ProseMirror pre .hljs-bullet {
  color: #b9f18d;
}

.ProseMirror pre .hljs-block {
  color: #9650c8;
}

.ProseMirror pre .hljs-string {
  color: #aa8500;
}

.ProseMirror pre .hljs-title,
.ProseMirror pre .hljs-section {
  color: #faf594;
}
.ProseMirror pre .hljs-keyword,
.ProseMirror pre .hljs-selector-tag {
  color: #70cff8;
}
.ProseMirror pre .hljs-emphasis {
  font-style: italic;
}
.ProseMirror pre .hljs-strong {
  font-weight: 700;
}

.ProseMirror-hideselection .diagram-showcase svg span {
  color: black;
  caret-color: black;
}

.ProseMirror iframe {
  border: 8px solid #000;
  border-radius: 4px;
  min-width: 200px;
  min-height: 200px;
  display: block;
  outline: 0px solid transparent;
  margin: auto;
}

.ProseMirror [data-youtube-video] {
  @apply mb-8;
}

.ProseMirror .tableWrapper iframe {
  max-width: 100%;
}

.ProseMirror .tableWrapper [data-youtube-video] {
  @apply mb-0;
}

.ProseMirror .code-lang-title:before {
  @apply text-xs absolute top-1.5 right-3 text-gray-500 transition-all;
  content: attr(title);
}

.ProseMirror .code-mermaid .btn-preview {
  @apply cursor-pointer inline-flex items-center justify-center border border-gray-700 rounded-md p-0.5 text-sm text-gray-300 bg-gray-900 shadow absolute top-2.5 right-3 opacity-0 transition-all;
}

.ProseMirror .code-mermaid:hover .code-lang-title:before{
  @apply opacity-0;
}
