.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 50;
  opacity: 1;
}

.close {
  position: absolute;
  top: 6px;
  right: 6px;
}

.close::after, .close::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  border-top: 2px solid #000;
  position: absolute;
  top: 7px;
  right: -8px;
  transform: rotate(-45deg);
}

.close::before {
  right: 6px;
  transform: rotate(45deg);
}

.close:hover {
  opacity: 0.3;
}

.modal-none {
  @apply ease-in duration-200 opacity-100 hidden
}

.modal-backdrop {
  @apply fixed inset-0 bg-gray-500 bg-opacity-25;
}

.modal-container {
  @apply flex items-center justify-center min-h-full p-4 text-center sm:p-0;
}

.modal-box {
  @apply rounded-lg relative text-left overflow-hidden shadow-xl transform transition-all;
  background-color: var(--modal-bg-color);
  border: 1px solid var(--modal-border-color);
  color: var(--common-text-color);
}
