.titlebar {
  @apply cursor-pointer flex justify-between;
  background-color: var(--common-bg-color);
}

.titlebar:hover{
  background-color: var(--common-bg-color);
}

.ttb-icon {
  @apply text-base cursor-pointer px-1.5 py-0.5;
}

.ttb-icon:hover{
  color: var(--common-text-hover-color);
}

.ttb-icon.ttb-close {
  @apply text-red-400 hover:text-red-500;
}
