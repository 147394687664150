@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply h-screen overflow-hidden;

  /* --common-text-color: rgb(209 213 219); */
  /* --common-semidark-text-color: rgb(160 167 177); */
  /* --common-dark-text-color: rgb(107 114 128); */
  /* --common-text-hover-color: rgb(165 170 179); */
  /**/
  /* --common-bg-color: rgb(31 41 55); */
  /* --common-dark-bg-color: rgb(17 24 39); */
  /* --common-darker-bg-color: rgb(12 18 30); */
  /* --common-light-bg-color: rgb(55 65 81); */
  /**/
  /* --common-border-hl-color: rgb(253 227 124); */
  /* --common-border-light-color: rgb(55 65 81); */
  /**/
  /* --common-btn-bg-color: rgb(55 65 81); */
  /* --common-btn-bg-hover-color: rgb(75 85 99); */
  /* --common-btn-bg-active-color: rgb(17 24 39); */
  /**/
  /* --common-primary-color: rgb(234 179 8); */
  /* --common-primary-hover-color: rgb(253 224 71); */
  /* --common-primary-text-color: rgb(113 63 18); */
  /**/
  /* --common-border-color: rgb(17 24 39); */
  /* --sidebar-background-color: rgb(17 24 39); */
  /* --sidebar-text-color: rgb(107 114 128); */
  /* --sidebar-text-color-hover: rgb(156 163 175); */
  /* --sidebar-title-color: rgb(156 163 175); */
  /**/
  /* --sidebar-user-setting-border-color: rgb(31 41 55); */
  /* --sidebar2-background-color: rgb(31 41 55); */
  /* --sidebar2-item-active-color: rgb(78 73 59); */
  /* --sidebar2-item-hover-color: rgb(209 201 164); */
  /**/
  /* --editor-text-color: rgb(209 213 219); */
  /* --editor-link-text-color: rgb(251 210 81); */
  /* --editor-quote-text-color: rgb(249 247 193); */
  /**/
  /* --modal-bg-color: rgb(31 41 55); */
  /* --modal-footer-bg-color: rgb(31 41 55); */
  /* --modal-border-color: white; */
  /* --setting-bg-color: rgb(31 41 55); */
  /* --sign-bg-color: rgb(31 41 55); */
  /* --sign-form-bg-color: rgb(55 65 81); */

  /* --dropdown-bg-color: rgb(251 241 199); */
  /* --dropdown-bg-hover-color: rgb(243 231 183); */
  /* --tag-bg-color: rgb(237 224 167); */
  /* --tag-text-color: rgb(136 130 112); */
  /**/
  /* --button-border-active-color: rgb(61 64 91); */
  /* --button-text-active-color: rgb(255 255 255); */

  /* color: var(--common-text-color); */
  background-color: var(--common-bg-color);
}

#theme-setting {
  color: var(--common-text-color);
  border: 1px solid var(--common-border-light-color);
  height: calc(100vh - 0px);
  overflow: hidden;
}

.bg {
  background-color: var(--common-bg-color);
}

.bg-primary {
  background-color: var(--common-primary-color);
}

.bg-dark {
  background-color: var(--common-dark-bg-color);
}

.bg-light {
  background-color: var(--common-light-bg-color);
}

.text-color-primary {
  color: var(--common-primary-color);
}

.text-color-base {
  color: var(--common-text-color);
}

.text-color-light {
  color: var(--common-semidark-text-color);
}

.text-color-dark {
  color: var(--common-dark-text-color);
}

.border-bottom {
  border-bottom: 1px solid var(--common-border-light-color);
}

.border-color-base {
  border-color: var(--common-border-light-color);
}

.border-color-dark {
  border-color: var(--common-border-color);
}

.btn-color-base {
  background-color: var(--common-btn-bg-color);
}

.div-y > :not(:first-child) {
  border-top: 1px solid var(--common-border-light-color);
}

.App main {
  @apply flex-grow;
  background-color: var(--common-bg-color);
}

.main-content {
  @apply w-full;
}

.layout-clear .titlebar {
  @apply fixed w-full hover:bg-transparent;
}

::selection {
  @apply bg-indigo-500 text-white;
}

@import url("./modal.css");
@import url("./scrollbar.css");
@import url("./form.css");
@import url("./advanced-setting.css");
@import url("./prosemirror.css");
@import url("./tiptap.css");
@import url("./button.css");
@import url("./sign.css");
@import url("./sidebar.css");
@import url("./pad.css");
@import url("./dropdown.css");
@import url("./theme.css");
@import url("./word-count.css");
@import url("./mermaid.css");
@import url("./command-palletes.css");
@import url("./listbox.css");

